import React from 'react';
import {Card} from "react-bootstrap";
import {addProductToCart, clearCartAction, finishPurchase} from "../utils/cartSlice";
import {AppDispatch} from "../utils/store";
import {useDispatch, useSelector} from "react-redux";
import Package from "../models/Package";
import {Box, Button, Typography} from "@mui/material";
import {ShoppingCart as ShoppingCartIcon} from "@material-ui/icons";
import {makeStyles} from '@material-ui/core';
import {hideModal, Modals, showModal} from "../utils/modalSlice";
import {selectIsLoggedIn} from "../utils/userSlice";
import {styled} from "@mui/material/styles";
import {prettifyCurrency} from "../utils/utils";

const useStyles = makeStyles((theme) => ({
    buttonWithIcon: {
        '& .MuiButton-startIcon': {
            marginRight: '-6px',
            marginLeft: '2px',
        },
        color: 'black'
    },
}));

const StyledButton = styled(Button)(({theme}) => ({
    [theme.breakpoints.between('md', 'lg')]: {
        width: '110px',
        fontSize: '16px !important'
    },
}));

const PackageComponent: React.FC<Package> = (pack) => {
    const classes = useStyles();
    const dispatch: AppDispatch = useDispatch();

    const isLoggedIn = useSelector(selectIsLoggedIn);

    const handleAddProductToCart = () => {
        if (isLoggedIn) {
            dispatch(addProductToCart({...pack}))
        } else {
            dispatch(showModal(Modals.LOGIN))
        }
    }

    const handleBuyNow = async () => {
        if (isLoggedIn) {
            try {
                dispatch(clearCartAction())
                await dispatch(addProductToCart({...pack}, false))
                dispatch(finishPurchase(null))
            } catch (e) {
                dispatch(hideModal(Modals.MOVING_TO_PAYMENT))
            }
        } else {
            dispatch(showModal(Modals.LOGIN))
        }
    }

    return (
        <div key={pack.id}>
            <Card style={{
                width: "100%",
                height: pack.emphasize?.enabled ? "410px" : "370px",
                borderRadius: "10px",
                boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.12)",
                border: pack.emphasize?.enabled ? `2px solid ${pack.emphasize?.color}` : ''
            }}>
                {pack.emphasize?.enabled && <Card.Header style={{
                    height: '40px',
                    background: pack.emphasize?.color,
                    color: 'white',
                    textAlign: 'center',
                    fontWeight: 700,
                    fontSize: "18px",
                    lineHeight: "18px"
                }}>{pack.emphasize?.title}</Card.Header>}
                <Card.Body className={"d-flex flex-column justify-content-between h-100"}
                           style={{padding: "24px 16px"}}>
                    <div>
                        <Typography variant="h5" className="mb-2">{`${pack.name}`}</Typography>
                        <div className="overflow-y-auto">
                            <Typography variant="body1">
                                {pack.info?.description}
                            </Typography>
                            {(pack.info?.bullets?.length ?? 0) > 0 && <ul>
                                {pack.info?.bullets?.map((b, index) =>
                                    <li key={`bullet-${index}`}>{b}</li>
                                )}
                            </ul>}
                        </div>
                    </div>
                    <div>
                        <Box className="mb-2">
                            {(pack.info?.oldPrice ?? 0) > 0 ?
                                <>
                                    <Typography variant="h4"
                                                style={{
                                                    marginLeft: "5px",
                                                    display: "inline-block",
                                                    color: "#251FB6",
                                                    fontWeight: 700,
                                                    lineHeight: 1.2
                                                }}>{prettifyCurrency(pack.price)}₪</Typography>
                                    <Typography variant="h5" style={{
                                        display: "inline-block",
                                        color: "rgba(48, 48, 48, 0.50)",
                                        fontWeight: 300,
                                        textDecoration: "line-through"
                                    }}>{prettifyCurrency(pack.info.oldPrice!)}₪</Typography>
                                </> :
                                <Typography variant="h5">{prettifyCurrency(pack.price)}₪</Typography>
                            }
                            <Box style={{marginTop: "-10px"}}>
                                <Typography variant="subtitle2">לא כולל מע״מ</Typography>
                            </Box>
                        </Box>

                        <StyledButton color="secondary" variant={"contained"}
                                      className={`simple-button blue`}
                                      style={{marginLeft: "10px"}}
                                      onClick={handleBuyNow}>
                            קנה עכשיו
                        </StyledButton>
                        <StyledButton color="primary" variant={"outlined"}
                                      className={`${classes.buttonWithIcon} simple-button white`}
                                      startIcon={<ShoppingCartIcon/>}
                                      onClick={handleAddProductToCart}>
                            הוסף לסל
                        </StyledButton>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
};

export default PackageComponent;
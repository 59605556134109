import {useApiService} from "../../../context/ApiServiceContext";
import React, {useEffect, useRef, useState} from "react";
import {toast} from "react-toastify";
import Select from "react-select";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

interface InfiniteAutocompleteSelect {
    fetchEndpoint: string;
    entityName: string;
    filterProp: 'email' | 'name';
    handleSelected: (selectedValue?: string) => void;
    clearInput: boolean;
    sx?: SxProps<Theme>;
}

const InfiniteAutocompleteSelect = ({
                                        fetchEndpoint,
                                        entityName,
                                        filterProp,
                                        handleSelected,
                                        clearInput,
                                        sx,
                                    }: InfiniteAutocompleteSelect) => {
    const apiService = useApiService();
    const [entities, setEntities] = useState<string[]>([]);
    const [page, setPage] = useState<number>(1);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");

    const inputRef = useRef(null);

    const maxEntitiesPerPage = 10;

    const fetchEntities = (page: number, name: string = "") => {
        setLoading(true);

        const params: { perPage: number; page: number; filterProp: 'email' | 'name'; name?: string; email?: string } = {
            perPage: maxEntitiesPerPage,
            page,
            filterProp,
        };
        if (name.trim().length > 0) {
            params[filterProp] = name;
        }

        apiService.api
            .get<string[]>(fetchEndpoint, {params})
            .then((response) => {
                if (response.data.length < maxEntitiesPerPage) setHasMore(false);
                setEntities((prev) =>
                    page === 1 ? response.data : [...prev, ...response.data]
                );
                setLoading(false);
            })
            .catch(() => {
                toast.error(`שגיאה בטעינת ${entityName}`);
                setLoading(false);
            });
    };

    // Debounce Effect
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 300); // 300ms debounce

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);

    // Debounce Effect
    useEffect(() => {
        if (clearInput) {
            (inputRef.current as any)?.clearValue()
        }
    }, [clearInput]);

    // Trigger fetch when page or debounced search term changes
    useEffect(() => {
        if (!clearInput) {
            fetchEntities(page, debouncedSearchTerm);
        }
    }, [page, debouncedSearchTerm, clearInput]);

    const handleSearchChange = (newValue?: string) => {
        clearInput = false
        setSearchTerm(newValue || "");
        setPage(1); // Reset to the first page on new search
        setHasMore(true);
    };

    const options = entities.map((entity) => ({
        value: entity,
        label: entity,
    }));

    return (
        <Select
            ref={inputRef}
            id="entity-select"
            options={options}
            onInputChange={(text, actionMeta) => {
                if (actionMeta.action == 'input-change') {
                    handleSearchChange(text)
                }
            }}
            onChange={(selectedOption) => {
                console.log("handleSelected", selectedOption?.value, clearInput)
                if (!clearInput) {
                    if (!selectedOption) {
                        handleSearchChange()
                    }
                    handleSelected(selectedOption?.value)
                }
            }}
            isClearable
            isLoading={loading}
            placeholder={`חפש לפי ${entityName}`}
            menuPortalTarget={document.body}
            menuPosition="absolute"
            onMenuScrollToBottom={() => {
                if (hasMore && !loading) {
                    setPage((prevPage) => prevPage + 1);
                }
            }}
            onMenuClose={() => {
                // do nothing, we still have searchTerm in state
            }}
            styles={{
                container: (provided) => ({
                    ...provided,
                    width: "100%",
                }),
            }}
        />
    );
};

export default InfiniteAutocompleteSelect;

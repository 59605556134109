import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../utils/store';
import {
    AppBar,
    Badge,
    Box,
    Drawer,
    IconButton,
    Menu,
    MenuItem,
    Popover,
    Theme,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import {AccountCircle, Menu as MenuIcon, ShoppingCart as ShoppingCartIcon} from '@material-ui/icons';
import {Link} from "react-router-dom";
import ShoppingCart from "./ShoppingCart";
import {setCart} from "../utils/cartSlice";
import logo from '../assets/images/linkngo-logo.png';
import {makeStyles} from '@material-ui/core';
import ProfileModal from "./ProfileModal";
import {selectIsLoggedIn, selectUser, setUser} from "../utils/userSlice";
import {hideModal, Modals, showModal} from "../utils/modalSlice";
import LoginModal from "./modals/LoginModal";
import RegisterModal from "./modals/RegisterModal";
import {styled} from "@mui/material/styles";
import {useApiService} from "../context/ApiServiceContext";
import {Col, Row} from 'react-bootstrap';
import ForgotPasswordModal from "./modals/ForgotPasswordModal";
import CartItem from "../models/CartItem";

const useStyles = makeStyles((theme: Theme) => ({
    links: {
        '& a:not(:last-child)': {
            marginLeft: '20px',
        },
        '& a': {
            color: 'black',
            textDecoration: 'none'
        }
    },
    menuButton: {
        marginRight: '16px',
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            marginRight: 'unset',
            display: 'block',
        }
    },
    appbar: {
        height: '92px',
        padding: '24px 64px',
        borderBottom: '1px solid #C9C9C9',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            height: 'auto',
        }
    },
    hideOnMobile: {
        '@media (max-width: 768px)': {
            display: 'none',
        },
    },
    logo: {
        width: '120px',
        position: "relative",
        [theme.breakpoints.up('sm')]: {
            right: "-60px"
        },
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            margin: "auto"
        }
    },
    logoWrapper: {
        flexGrow: 1
    }
}));

const StyledPopover = styled(Popover)(({theme}) => ({
    '& .MuiPaper-root': {
        borderRadius: "16px",
        width: "340px",
        maxWidth: "95%"
    }
}));

const NavBar: React.FC<{
    // ref: ForwardedRef<HTMLUListElement>
}> = () => {
    const dispatch = useDispatch();

    const apiService = useApiService();
    const classes = useStyles();
    const cartItems = useSelector((state: RootState) => state.cart.items);
    const user = useSelector(selectUser);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const cartIconButtonRef = useRef(null); // Ref for the IconButton

    const isLoggedIn = useSelector(selectIsLoggedIn);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(768));

    useEffect(() => {
        if (isLoggedIn) {
            // Here we fetch the cart from the API and dispatch setCart action
            apiService.api.get('/cart').then((response) => {
                dispatch(setCart(response.data.items.map((item: CartItem) => ({
                    ...item,
                    price: item.price / (item.quantity > 0 ? item.quantity : 1)
                }))));
            })
                .catch(e => {
                    /*empy catch block*/
                });
            apiService.api.get('/user').then((response) => {
                dispatch(setUser(response.data));
            })
                .catch(e => {
                    /*empy catch block*/
                });
        }
    }, [dispatch, isLoggedIn]);

    const handleCartClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (isLoggedIn) {
            dispatch(showModal(Modals.CART));
        } else {
            dispatch(showModal(Modals.LOGIN))
        }
    };

    const handleCartClose = () => {
        dispatch(hideModal(Modals.CART));
    };

    const handleProfileModalOpen = () => {
        if (isLoggedIn) {
            dispatch(showModal(Modals.PROFILE))
        } else {
            dispatch(showModal(Modals.LOGIN))
        }
    };


    const isProfileModalVisible = useSelector((state: RootState) => state.modals[Modals.PROFILE]);
    const isLoginModalVisible = useSelector((state: RootState) => state.modals[Modals.LOGIN]);
    const isRegisterModalVisible = useSelector((state: RootState) => state.modals[Modals.REGISTER]);
    const isCartVisible = useSelector((state: RootState) => state.modals[Modals.CART]);
    const isForgotPasswordVisible = useSelector((state: RootState) => state.modals[Modals.FORGOT_PASSWORD]);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    // Close submenu
    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const renderMenuItems = () => (
        <>
            <MenuItem component={Link} to="/">עמוד הבית</MenuItem>
            <MenuItem component={Link} to="/boost">קישורי בוסט</MenuItem>
            <MenuItem component={Link} to="/super">קישורי סופר ריגו</MenuItem>
            <MenuItem component={Link} to="/premium">קישורי פרימיום</MenuItem>
            <MenuItem component={Link} to="/videos">סרטוני הדרכה</MenuItem>
            <MenuItem component={Link} to="/recommendations">המלצות חמות</MenuItem>
            <MenuItem component={Link} to="/page/about">אודות</MenuItem>
            {/*<MenuItem component={Link} to="/legal">מסמכים משפטיים</MenuItem>*/}
            {/* Other MenuItems go here */}
        </>
    );

    return (
        <AppBar className={classes.appbar} elevation={0}
                position="sticky" color="primary">
            <Toolbar variant="dense">
                {isMobile && (
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu"
                                onClick={handleDrawerToggle}>
                        <MenuIcon/>
                    </IconButton>
                )}
                <Row className="w-100">
                    {!isMobile && (<Col md={6}>
                        <Typography variant="h6" className={classes.links} style={{width: "80%"}}>
                            <Link to="/">עמוד הבית</Link>
                            <span className="me-4 cu"
                                  onMouseOver={handleMenuOpen}
                                  style={{cursor: 'pointer'}}>
                                קישורים
                            </span>
                            <Link to="/videos">סרטוני הדרכה</Link>
                            <Link to="/recommendations">המלצות חמות</Link>
                            <Link to="/page/about">אודות</Link>
                        </Typography>

                    </Col>)}
                    <Col xs={12} md={6} className="d-flex">
                        <a href="#/" className={classes.logoWrapper}>
                            <img src={logo} alt="Link&Go Logo" className={classes.logo}/>
                        </a>
                        <div className="d-flex align-items-center me-4">
                            {user && <Typography align='center'>שלום {user.name}</Typography>}
                        </div>
                        <IconButton color="inherit" ref={cartIconButtonRef} onClick={handleCartClick}>
                            <Badge badgeContent={cartItems.length} color="secondary" overlap="rectangular">
                                <ShoppingCartIcon/>
                            </Badge>
                        </IconButton>
                        <IconButton color="inherit" onClick={handleProfileModalOpen}>
                            <AccountCircle/>
                        </IconButton>
                    </Col>
                </Row>
                {isCartVisible && <StyledPopover
                    open={isCartVisible}
                    anchorEl={cartIconButtonRef.current}
                    onClose={handleCartClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <ShoppingCart handleClose={handleCartClose}/>
                </StyledPopover>}
            </Toolbar>

            <Drawer classes={{paper: "w-50"}} anchor="right"
                    open={drawerOpen} onClose={handleDrawerToggle}>
                <Box role="presentation" onClick={handleDrawerToggle} onKeyDown={handleDrawerToggle}>
                    {renderMenuItems()}
                </Box>
            </Drawer>

            <Menu
                id="links-menu"
                anchorEl={menuAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                open={Boolean(menuAnchorEl)}
                onClose={handleMenuClose}
                MenuListProps={{onMouseLeave: handleMenuClose}}
            >
                <MenuItem onClick={handleMenuClose} component={Link} to="/boost">קישורי בוסט</MenuItem>
                <MenuItem onClick={handleMenuClose} component={Link} to="/super">קישורי סופר ריגו</MenuItem>
                <MenuItem onClick={handleMenuClose} component={Link} to="/premium">קישורי פרימיום</MenuItem>
            </Menu>
            <ProfileModal open={isProfileModalVisible} handleClose={() => dispatch(hideModal(Modals.PROFILE))}/>
            <LoginModal show={isLoginModalVisible} handleClose={() => dispatch(hideModal(Modals.LOGIN))}/>
            <RegisterModal show={isRegisterModalVisible} handleClose={() => dispatch(hideModal(Modals.REGISTER))}/>
            <ForgotPasswordModal show={isForgotPasswordVisible}
                                 handleClose={() => dispatch(hideModal(Modals.FORGOT_PASSWORD))}/>
        </AppBar>
    );
};

export default NavBar;
import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Form, InputGroup, Modal, Row, Table} from 'react-bootstrap';
import {toast} from "react-toastify";
import ConfirmationModal from "../../utils/ConfirmationModal";
import * as yup from 'yup';
import {useFormik} from 'formik';
import {AxiosResponse} from "axios";
import {useApiService} from "../../context/ApiServiceContext";
import Category from "../../models/Category";
import Package from "../../models/Package";
import {CircularProgress} from "@mui/material";
import {SiteType} from "../../models/Link";

export interface PackageRequestBody {
    id?: string;
    name: string;
    linksCount: number;
    categoryId: string;
    subCategoryIds: string[];
    siteType: string;
    price: number;
    thumbnail?: string;
    info: {
        title?: string;
        description?: string;
        bullets?: string[];
    };
    emphasize?: {
        title: string;
        color: string;
        enabled: boolean;
    };
}

const AdminPackages: React.FC = () => {
    const apiService = useApiService();
    const [categories, setCategories] = useState<Category[]>([]);
    const [packages, setPackages] = useState<Package[]>([]);
    const [selectedPackage, setSelectedPackage] = useState<Package | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [isFileUploading, setFileUploading] = useState<boolean>(false);

    /* Pagination */
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const linksPerPage = 20; // Adjust as needed
    /* End of Pagination */

    const [selectedSubCategories, setSelectedSubCategories] = useState<{ id: string, name: string }[]>([]);

    // Function to handle subcategory selection
    const handleSelectSubCategory = (categoryId: string) => {
        const category = categories.find(c => c.id === categoryId);
        if (category && !selectedSubCategories.find(sc => sc.id === categoryId)) {
            setSelectedSubCategories([...selectedSubCategories, {id: categoryId, name: category.name}]);
        }
    };

    // Function to remove a selected subcategory
    const removeSubCategory = (categoryId: string) => {
        setSelectedSubCategories(selectedSubCategories.filter(sc => sc.id !== categoryId));
    };

    useEffect(() => {
        formik.setFieldValue("subCategoryIds", selectedSubCategories.map(sc => sc.id));
    }, [selectedSubCategories]);

    const fetchPackages = useCallback(async (page = 1) => {
        try {
            const res = await apiService.api.get(`/package/list?page=${page}&per_page=${linksPerPage}`);
            if (Array.isArray(res.data)) {
                setPackages(prevPackages => [...prevPackages, ...res.data]);
                setHasMorePages(res.data.length === linksPerPage);
            }
        } catch (error: any) {
            toast.error(`בעיה בעת משיכת החבילות: ${error.message}`);
        }
    }, [apiService, linksPerPage]);

    const fetchCategories = useCallback(async () => {
        try {
            const res = await apiService.api.get('/category/list?per_page=50');
            setCategories(res.data);
        } catch (error: any) {
            toast.error(`בעיה בעת משיכת הקטגוריות: ${error.message}`);
        }
    }, [apiService]);

    useEffect(() => {
        fetchPackages(currentPage);
        fetchCategories();
    }, [currentPage, fetchPackages, fetchCategories]);

    const apiCall = useCallback(async (apiFunc: () => Promise<any>, successMessage: string, errorMessage: string) => {
        try {
            const res = await apiFunc();
            toast.success(successMessage);
            return res.data;
        } catch (error) {
            toast.error(errorMessage);
        }
    }, []);

    const handleDeleteConfirmation = useCallback(() => {
        if (selectedPackage) {
            const packageId = selectedPackage.id
            apiCall(
                () => apiService.api.delete(`/package/${packageId}`),
                'החבילה נמחקה בהצלחה',
                'בעיה בעת מחיקת החבילה'
            ).then(() => {
                setPackages(prevPackages => prevPackages.filter(pkg => pkg.id !== packageId));
                setSelectedPackage(null)
                setSelectedSubCategories([])
                setDeleteModalShow(false)
            });
        }
        setSelectedPackage(null)
        setSelectedSubCategories([])
    }, [selectedPackage, apiCall]);

    const handleUpdate = (packageId: string, updatedPackageData: PackageRequestBody) => {
        apiService.api
            .put(`/package/${packageId}`, updatedPackageData)
            .then((response: AxiosResponse<Package>) => {
                setPackages(prevPackages =>
                    prevPackages.map(pkg =>
                        pkg.id === packageId ? {...pkg, ...response.data} : pkg
                    )
                );
                toast.success('החבילה עודכנה בהצלחה');
            })
            .catch(() => {
                toast.error('תקלה בעת עדכון החבילה');
            });

        setShowModal(false);
    };

    const handleCreate = (newPackageData: PackageRequestBody) => {
        apiService.api
            .post('/package/', newPackageData)
            .then((packageRes: AxiosResponse<Package>) => {
                setPackages(prevPackages => [...prevPackages, packageRes.data]);
                toast.success('החבילה הוספת בהצלחה');
            })
            .catch(() => {
                toast.error('בעיה בעת הוספת החבילה');
            });

        setShowModal(false);
    };

    const openModal = (pkg: Package | null) => {
        setSelectedPackage(pkg);
        setSelectedSubCategories(pkg?.subCategories ?? [])

        formik.resetForm({
            values: {
                packageName: pkg ? pkg.name : '',
                linksCount: pkg ? pkg.linksCount : 0,
                categoryId: pkg ? pkg.category.id : '',
                subCategoryIds: pkg ? pkg.subCategories.map(x => x.id) : [],
                siteType: pkg ? pkg.siteType : '',
                price: pkg ? pkg.price : 0,
                thumbnail: pkg ? pkg.thumbnail || '' : '',
                info: {
                    title: pkg ? pkg.info?.title || '' : '',
                    description: pkg ? pkg.info?.description || '' : '',
                    bullets: [...(pkg?.info?.bullets || []), '', '', ''].slice(0, 3),
                    oldPrice: pkg ? pkg.info?.oldPrice || 0 : 0
                },
                emphasize: {
                    title: pkg?.emphasize?.title || "המשתלם ביותר",
                    color: pkg?.emphasize?.color || "#837eff",
                    enabled: pkg?.emphasize?.enabled || false
                }
            }
        });

        setShowModal(true);
    };

    const openDeleteModal = (pkg: Package) => {
        setSelectedPackage(pkg);
        setDeleteModalShow(true);
    };

    // Package validation schema
    const packageSchema = yup.object().shape({
        packageName: yup.string()
            .required('שם החבילה נדרש')
            .min(2, 'שם החבילה קצר מדי')
            .max(50, 'שם החבילה ארוך מדי'),
        linksCount: yup.number()
            .required('נדרש מספר קישורים')
            .min(0, 'מספר הקישורים לא יכול להיות שלילי'),
        categoryId: yup.string()
            .required('קטגוריה נדרשת'),
        subCategoryIds: yup.array().of(yup.string().required('תת-קטגוריות נדרשת'))
            .min(0, 'נדרשת לפחות תת-קטגוריות אחת'),
        siteType: yup.string()
            .oneOf([SiteType.BOOST, SiteType.SUPER_REGO], 'סוג האתר חייב להיות boost או סופר ריגו')
            .required('סוג האתר נדרש'),
        price: yup.number()
            .required('מחיר נדרש')
            .min(0, 'המחיר לא יכול להיות שלילי'),
        info: yup.object({
            description: yup.string(),
            bullets: yup.array().of(yup.string()),
            oldPrice: yup.number()
                .min(0, 'המחיר לא יכול להיות שלילי'),
        }),
    });


    const formik = useFormik({
        initialValues: {
            packageName: '',
            linksCount: 0,
            categoryId: '',
            subCategoryIds: [] as string[],
            siteType: '',
            thumbnail: '',
            price: 0,
            info: {
                title: '',
                description: '',
                bullets: ['', '', ''],
                oldPrice: 0,
            },
            emphasize: {
                title: "המשתלם ביותר",
                color: "#837eff",
                enabled: false
            }
        }, // Add other fields
        validationSchema: packageSchema,
        onSubmit: values => {
            if (formik.isValid) {
                const packageData = {
                    name: values.packageName,
                    linksCount: values.linksCount,
                    categoryId: values.categoryId,
                    subCategoryIds: values.subCategoryIds,
                    siteType: values.siteType,
                    price: values.price,
                    thumbnail: values.thumbnail,
                    info: {
                        title: values.info?.title,
                        description: values.info?.description,
                        bullets: values.info?.bullets?.filter(bullet => bullet.trim() !== ''),
                        oldPrice: values.info?.oldPrice,
                    },
                    emphasize: {
                        title: values.emphasize?.title,
                        color: values.emphasize?.color,
                        enabled: values.emphasize?.enabled,
                    },
                };

                if (selectedPackage) {
                    handleUpdate(selectedPackage.id, packageData);
                } else {
                    handleCreate(packageData);
                }
            }
        },
        validateOnBlur: true,
        validateOnMount: true,
    });

    const handleFileChange = (files: File[]) => {
        if (files && files.length > 0) {
            setFileUploading(true)

            const formData = new FormData();
            files.forEach((file, index) => {
                formData.append(`file${index}`, file);
            });

            apiService.api.post(`/package/thumbnail/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    formik.setFieldValue('thumbnail', response.data.urls[0]);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setFileUploading(false)
                });
        }
    };

    const PaginationBar = () => {
        return (
            <div className="d-flex justify-content-center my-3">
                <Button
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage(currentPage - 1)}
                    className="me-2"
                >
                    הקודם
                </Button>
                <Button
                    disabled={!hasMorePages}
                    onClick={() => setCurrentPage(currentPage + 1)}
                >
                    הבא
                </Button>
            </div>
        );
    };

    return (
        <>
            <Row className="justify-content-center py-md-5">
                <Col md={5}>
                    <h1 className="mb-2">חבילות</h1>
                </Col>
                <Col md={5} className="d-flex justify-content-end align-items-center">
                    <Button onClick={() => openModal(null)}>הוספת חבילה</Button>
                </Col>
                <Col md={10}>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>שם החבילה</th>
                            <th>מספר לינקים</th>
                            <th>קטגוריה</th>
                            <th>תת-קטגוריות</th>
                            <th>סוג האתר</th>
                            <th>מחיר</th>
                            <th>מחיר לפני הנחה</th>
                            <th>חבילה מודגשת</th>
                            <th>פעולות</th>
                        </tr>
                        </thead>
                        <tbody>
                        {packages.map((pkg) => (
                            <tr key={pkg.id}>
                                <td>{pkg.name}</td>
                                <td>{pkg.linksCount}</td>
                                <td>{pkg.category.name}</td>
                                <td>{pkg.subCategories.map(x => x.name).join(", ")}</td>
                                <td>{pkg.siteType}</td>
                                <td>{pkg.price}</td>
                                <td>{pkg.info?.oldPrice}</td>
                                <td>{pkg.emphasize?.enabled ? '✔️' : ''}</td>
                                <td>
                                    <Button className="me-2" onClick={() => openModal(pkg)}>עריכה</Button>
                                    <Button variant="danger" onClick={() => openDeleteModal(pkg)}>מחק</Button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    <PaginationBar/>
                </Col>
            </Row>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedPackage ? 'ערוך חבילה' : 'הוסף חבילה חדשה'}</Modal.Title>
                </Modal.Header>
                <Form noValidate onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <Form.Group className="mb-3">
                            <Form.Label>שם החבילה</Form.Label>
                            <Form.Control
                                type="text"
                                name="packageName"
                                value={formik.values.packageName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.packageName && !!formik.errors.packageName}
                                placeholder="הכנס את שם החבילה"
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.packageName}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>כמות קישורים</Form.Label>
                            <Form.Control
                                type="number"
                                name="linksCount"
                                value={formik.values.linksCount}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.linksCount && !!formik.errors.linksCount}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.linksCount}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>קטגוריה</Form.Label>
                            <Form.Control
                                as="select"
                                name="categoryId"
                                value={formik.values.categoryId}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.categoryId && !!formik.errors.categoryId}
                            >
                                <option value="">בחר קטגוריה</option>
                                {categories.map(category => (
                                    <option key={category.id} value={category.id}>{category.name}</option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.categoryId}
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/*<Form.Group className="mb-3">*/}
                        {/*    <Form.Label>תת-קטגוריות</Form.Label>*/}
                        {/*    <Form.Control*/}
                        {/*        as="select"*/}
                        {/*        onChange={(e) => handleSelectSubCategory(e.target.value)}*/}
                        {/*        defaultValue=""*/}
                        {/*    >*/}
                        {/*        <option value="" disabled>בחר תת-קטגוריות</option>*/}
                        {/*        {categories.map(category => (*/}
                        {/*            <option key={category.id} value={category.id}>{category.name}</option>*/}
                        {/*        ))}*/}
                        {/*    </Form.Control>*/}
                        {/*</Form.Group>*/}

                        {/*/!* Display Selected Subcategories *!/*/}
                        {/*<ul>*/}
                        {/*    {selectedSubCategories.map(sc => (*/}
                        {/*        <li key={sc.id}>*/}
                        {/*            {sc.name}*/}
                        {/*            <Button variant="danger" className="p-0 ms-1"*/}
                        {/*                    style={{width: "20px", height: "20px", lineHeight: "12px"}}*/}
                        {/*                    onClick={() => removeSubCategory(sc.id)}>X</Button>*/}
                        {/*        </li>*/}
                        {/*    ))}*/}
                        {/*</ul>*/}

                        <Form.Group className="mb-3">
                            <Form.Label>סוג האתר</Form.Label>
                            <Form.Control
                                as="select"
                                name="siteType"
                                value={formik.values.siteType}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.siteType && !!formik.errors.siteType}
                            >
                                <option value="">בחר סוג אתר</option>
                                <option value="boost">Boost</option>
                                <option value="super">SuperRego</option>
                                <option value="premium">Premium</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.siteType}
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/*<Form.Group className="mb-3">*/}
                        {/*    <Form.Label>אתר חזק</Form.Label>*/}
                        {/*    <Form.Check*/}
                        {/*        type="checkbox"*/}
                        {/*        name="powerSite"*/}
                        {/*        label="אתר חזק"*/}
                        {/*        checked={formik.values.powerSite}*/}
                        {/*        onChange={formik.handleChange}*/}
                        {/*    />*/}
                        {/*</Form.Group>*/}

                        <Form.Group className="mb-3">
                            <Form.Label>מחיר</Form.Label>
                            <Form.Control
                                type="number"
                                name="price"
                                value={formik.values.price}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.price && !!formik.errors.price}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.price}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>מחיר לפני הנחה</Form.Label>
                            <Form.Control
                                type="number"
                                name="info.oldPrice"
                                value={formik.values.info.oldPrice}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.info?.oldPrice && !!formik.errors.info?.oldPrice}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.info?.oldPrice}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>תמונה
                                {formik.values.thumbnail && formik.values.thumbnail.trim() !== "" &&
                                    <>
                                        {" ("}
                                        <a href={formik.values.thumbnail} target="_blank">פתח בחלון חדש</a>
                                        {")"}
                                    </>
                                }
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="thumbnail"
                                placeholder="הכנס תמונה ממוזערת"
                                value={formik.values.thumbnail}
                                onChange={formik.handleChange}
                                isInvalid={formik.touched.thumbnail && !!formik.errors.thumbnail}
                                disabled
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.thumbnail}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId={`file`}>
                            <Form.Label>
                                <div>
                                    <span>העלאת קובץ לתמונה</span>
                                    <CircularProgress className="ms-3" color="secondary"
                                                      size={13}
                                                      hidden={!isFileUploading}/>
                                </div>
                            </Form.Label>
                            <Form.Control
                                type="file"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    handleFileChange(e.target.files ? Array.from(e.target.files) : [])}
                                accept=".png,.jpg,.jpeg,.webp"
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>תיאור</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={2}
                                name="info.description"
                                placeholder="הכנס תיאור"
                                value={formik.values.info.description}
                                onChange={formik.handleChange}
                                isInvalid={formik.touched.info?.description && !!formik.errors.info?.description}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.info?.description}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>נקודה 1</Form.Label>
                            <Form.Control
                                type="text"
                                name="info.bullets[0]"
                                value={formik.values.info.bullets?.[0]}
                                onChange={formik.handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>נקודה 2</Form.Label>
                            <Form.Control
                                type="text"
                                name="info.bullets[1]"
                                value={formik.values.info.bullets?.[1]}
                                onChange={formik.handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>נקודה 3</Form.Label>
                            <Form.Control
                                type="text"
                                name="info.bullets[2]"
                                value={formik.values.info.bullets?.[2]}
                                onChange={formik.handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Check
                                type="checkbox"
                                name="emphasize.enabled"
                                label="חבילה מודגשת"
                                checked={formik.values.emphasize?.enabled}
                                onChange={formik.handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" hidden={!formik.values.emphasize?.enabled}>
                            <Form.Label>כותרת ההדגשה</Form.Label>
                            <Form.Control
                                type="text"
                                name="emphasize.title"
                                value={formik.values.emphasize?.title}
                                onChange={formik.handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" hidden={!formik.values.emphasize?.enabled}>
                            <Form.Label>צבע ההדגשה</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    name="emphasize.color"
                                    value={formik.values.emphasize?.color}
                                    onChange={formik.handleChange}
                                />
                                <InputGroup.Text style={{
                                    background: formik.values.emphasize?.color,
                                    color: formik.values.emphasize?.color
                                }}>__</InputGroup.Text>
                            </InputGroup>
                        </Form.Group>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>סגור</Button>
                        <Button type="submit" variant="primary"
                                disabled={isFileUploading}>{selectedPackage ? 'עדכן' : 'צור'}</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <ConfirmationModal
                show={deleteModalShow}
                onClose={() => setDeleteModalShow(false)}
                onConfirm={handleDeleteConfirmation}
                title="אישור מחיקה"
                message="האם אתה בטוח שאתה רוצה למחוק את החבילה?"
            />
        </>
    );

};

export default AdminPackages;

import React, { useEffect, useState } from "react";
import { useApiService } from "../../../context/ApiServiceContext";
import { toast } from "react-toastify";
import { Button, Form, Modal } from "react-bootstrap";
import Link from "../../../models/Link";
import CustomSelect from "../../modals/views/CustomSelect";

interface AttachLinkToUserModalProps {
    userId: string;
    show: boolean;
    onHide: () => void;
}

const AttachLinkToUserModal: React.FC<AttachLinkToUserModalProps> = ({ userId, show, onHide }) => {
    const apiService = useApiService();
    const [selectedLink, setSelectedLink] = useState<Link | null>(null);
    const [isPremium, setIsPremium] = useState<boolean>(false);
    const [userContent, setUserContent] = useState<boolean>(true);
    const [wordCount, setWordCount] = useState<number | null>(null);

    useEffect(() => {
        if (selectedLink?.siteType === "premium") {
            setIsPremium(true);
            setUserContent(true);
            setWordCount(null);
        } else {
            setIsPremium(false);
            setUserContent(false);
            setWordCount(null);
        }
    }, [selectedLink]);

    const handleAttachLink = () => {
        if (!selectedLink) {
            toast.error("יש לבחור קישור");
            return;
        }

        if (isPremium && !userContent && !wordCount) {
            toast.error("אנא הזן מספר מילים");
            return;
        }

        apiService.api
            .post(`/admin/link/${selectedLink.id}/attach`, {
                userId,
                usersContent: isPremium ? userContent : undefined,
                wordCount: isPremium && !userContent ? wordCount || undefined: undefined
            })
            .then(() => {
                toast.success("הענקת הקישור הושלמה בהצלחה");
                onHide(); // Close the modal on success
            })
            .catch(() => {
                toast.error("בעיה בעת שיוך הקישור");
            });
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>בחר קישור</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CustomSelect
                    value={selectedLink?.website || ""}
                    onChange={setSelectedLink}
                    disabled={false}
                    isInvalid={!selectedLink}
                    required
                />

                {isPremium && (
                    <>
                        <Form.Check
                            type="checkbox"
                            label="המשתמש מספק את התוכן"
                            checked={userContent}
                            onChange={(e) => setUserContent(e.target.checked)}
                            className="mt-3"
                        />
                        {!userContent && (
                            <Form.Group controlId="wordCount" className="mt-3">
                                <Form.Label>מספר מילים</Form.Label>
                                <Form.Select
                                    aria-label="Select word count"
                                    value={wordCount || ""}
                                    onChange={(e) => setWordCount(Number(e.target.value))}
                                >
                                    <option value="" disabled>
                                        בחר מספר מילים
                                    </option>
                                    {Array.from({ length: 6 }, (_, i) => 500 + i * 100).map((wc) => (
                                        <option key={wc} value={wc}>
                                            {wc.toLocaleString("en-US")}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        )}
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    סגור
                </Button>
                <Button variant="primary" onClick={handleAttachLink}>
                    הענקת קישור
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AttachLinkToUserModal;
